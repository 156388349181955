import React, { useState, useContext, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import ChatWindow from '../components/ChatWindow';
import SignInPopup from '../components/SignInPopup';
import ProfileModal from '../components/ProfileModal';
import { useUser } from '../hooks/useUser';
import { AuthContext } from '../contexts/AuthContext';
import DisclaimerModal from '../components/DisclaimerModal';

const Home = () => {
    const { currentUser } = useContext(AuthContext);
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [showSignInPopup, setShowSignInPopup] = useState(false);
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);
    const {
        data: userData,
    } = useUser(showProfileModal);

    useEffect(() => {
        if (currentUser) {
            const hasSeenDisclaimer = localStorage.getItem(`hasSeenDisclaimer_${currentUser.uid}`);
            if (!hasSeenDisclaimer) {
                setShowDisclaimerModal(true);
            }
        }
    }, [currentUser]);

    const handleCloseDisclaimerModal = () => {
        setShowDisclaimerModal(false);
        if (currentUser) {
            localStorage.setItem(`hasSeenDisclaimer_${currentUser.uid}`, 'true');
        }
    };

    const handleSelectTopic = (topic) => {
        setSelectedTopic(topic);
        setIsSidebarOpen(false);
    };

    const handleShowSignInPopup = (shouldShow) => {
        setShowSignInPopup(shouldShow);
    };

    const handleShowProfileModal = (shouldShow) => {
        setShowProfileModal(shouldShow);
    };

    return (
        <div className="flex h-screen">
            <DisclaimerModal isOpen={showDisclaimerModal} onClose={handleCloseDisclaimerModal} />
            {/* Hamburger button, visible on small screens */}
            {!isSidebarOpen && (
                <header className="fixed top-0 left-0 z-50 flex items-center w-full p-4 bg-white md:hidden">
                    <button
                        aria-label="Open sidebar"
                        onClick={() => setIsSidebarOpen(true)}
                        className="mr-4"
                    >
                        <svg className="w-6 h-6" viewBox="0 0 24 24">
                            <path
                                d="M4 6h16M4 12h16M4 18h16"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                            />
                        </svg>
                    </button>
                    <h1 className="text-xl font-bold text-gray-800">Lexcha</h1>
                </header>
            )}

            {/* Sidebar */}
            <Sidebar
                isOpen={isSidebarOpen}
                onClose={() => setIsSidebarOpen(false)}
                onSelectTopic={handleSelectTopic}
                onSignIn={() => handleShowSignInPopup(true)}
                onProfileClick={() => handleShowProfileModal(true)}
            />

            {/* ChatWindow */}
            <ChatWindow
                selectedTopic={selectedTopic}
            />

            {showSignInPopup && <SignInPopup onClose={() => handleShowSignInPopup(false)} />}
            {showProfileModal && (
                <ProfileModal
                    user={userData}
                    googleUser={currentUser}
                    onClose={() => handleShowProfileModal(false)}
                />
            )}
        </div>
    );
};

export default Home;
