// UserMenu.js
import React, { useState, useEffect } from 'react';
import { FaUserCircle, FaSignOutAlt } from 'react-icons/fa';

const UserMenu = ({ currentUser, onProfileClick, onSignOut, onItemClick }) => {
    const [menuOpen, setMenuOpen] = useState(false);

    // Close the menu when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuOpen && !event.target.closest('.menu-container')) {
                setMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuOpen]);

    const handleItemClick = (action) => {
        if (action === 'profile') {
            onProfileClick();
        } else if (action === 'signOut') {
            onSignOut();
        }
        setMenuOpen(false);
        if (onItemClick) {
            onItemClick(); // Notify parent to close the sidebar
        }
    };

    return (
        <div className="relative menu-container">
            {/* User Info */}
            <div
                className="flex items-center cursor-pointer"
                onClick={() => setMenuOpen(!menuOpen)}
            >
                {currentUser.photoURL ? (
                    <img
                        src={currentUser.photoURL}
                        alt="User Avatar"
                        className="w-8 h-8 rounded-full"
                    />
                ) : (
                    <div className="w-8 h-8 rounded-full bg-gray-500 flex items-center justify-center text-white">
                        {currentUser.displayName ? currentUser.displayName.charAt(0) : 'U'}
                    </div>
                )}
                <span className="ml-2">{currentUser.displayName || 'User'}</span>
            </div>
            {/* Dropdown Menu */}
            {menuOpen && (
                <div className="absolute left-0 bottom-full mb-2 ml-2 w-48 bg-white border border-gray-300 rounded-md shadow-lg z-10">
                    <ul>
                        <li
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
                            onClick={() => handleItemClick('profile')}
                        >
                            <FaUserCircle className="mr-2" /> Profile
                        </li>
                        <li
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
                            onClick={() => handleItemClick('signOut')}
                        >
                            <FaSignOutAlt className="mr-2" /> Sign Out
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default UserMenu;
