import React from 'react';
import { useSignin } from '../hooks/useSignin';
import { signInWithGoogle, signOut } from '../services/authService';

const SignInPopup = ({ onClose }) => {
    const { mutate: signin } = useSignin();

    const handleGoogleSignIn = async () => {
        const user = await signInWithGoogle();
        if (user) {
            signin(null, {
                onSuccess: () => {
                    console.log('Sign-in successful');
                    onClose();
                },
                onError: async (error) => {
                    console.error("Backend sign-in failed:", error);
                    alert("Could not complete sign-in due to a server error. Please try again.");
                    await signOut();
                },
            });
        } else {
            console.error("Google Sign-In was unsuccessful");
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg w-80 p-6 relative">
                <h2 className="text-lg font-semibold mb-4 text-center">Login/Sign Up</h2>
                <p className="text-center mb-6">Login with Google</p>
                <div className="flex justify-center mb-4">
                    <button
                        onClick={handleGoogleSignIn}
                        className="flex items-center bg-white border border-gray-300 rounded-md p-1 hover:bg-gray-100"
                    >
                        <img
                            src="https://developers.google.com/identity/images/btn_google_signin_dark_normal_web.png"
                            alt="Google Sign-In"
                            className="w-full"
                        />
                    </button>
                </div>
                <p className="text-center text-sm mt-4">
                    By signing up or logging in, you are agreeing to our{' '}
                    <a href="#" className="text-blue-500 underline">Terms of Service</a> and{' '}
                    <a href="#" className="text-blue-500 underline">Privacy Policy</a>.
                </p>
                <button
                    onClick={onClose}
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                >
                    &#x2715;
                </button>
            </div>
        </div>
    );
};

export default SignInPopup;
