import React, { useState, useEffect, useRef, useContext } from 'react';
import { useQueryClient } from 'react-query';
import { FaRedo } from 'react-icons/fa';
import { AuthContext } from '../contexts/AuthContext';
import ChatMessage from './ChatMessage';
import ProfileModal from './ProfileModal';
import NoCreditsModal from './NoCreditsModal';
import { useMessages } from '../hooks/useMessages';
import { useUser } from '../hooks/useUser';
import SignInPopup from './SignInPopup';
import TypingIndicator from './TypingIndicator';
import TextareaAutosize from 'react-textarea-autosize';

const ChatWindow = ({ selectedTopic }) => {
    const [question, setQuestion] = useState('');
    const chatContainerRef = useRef(null);
    const textareaRef = useRef(null);
    const { currentUser } = useContext(AuthContext);
    const [showSignInPopup, setShowSignInPopup] = useState(false);
    const [isPreparedQuestion, setIsPreparedQuestion] = useState(false);

    const [localMessages, setLocalMessages] = useState([]);
    // Mode id 2
    const { refetchMessages, saveMessage, clearMessages } = useMessages(selectedTopic?.id, currentUser, 2);
    const { data: userData, isLoading: userLoading, isError: userError } = useUser(!!currentUser);
    const queryClient = useQueryClient();

    const [showNoCreditsModal, setShowNoCreditsModal] = useState(false);
    const [showProfileModal, setShowProfileModal] = useState(false);

    const [isAssistantTyping, setIsAssistantTyping] = useState(false);

    // Refetch messages and update localMessages when selectedTopic changes and user is authenticated
    useEffect(() => {
        if (selectedTopic && currentUser) {
            refetchMessages()
                .then(({ data }) => {
                    setLocalMessages(Array.isArray(data) ? data : []);
                })
                .catch((error) => {
                    console.error('Failed to fetch messages:', error);
                    setLocalMessages([]);
                });
        }
    }, [selectedTopic, currentUser]);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [localMessages, isAssistantTyping]);

    useEffect(() => {
        if (!currentUser) {
            setLocalMessages([]);
        }
    }, [currentUser]);

    const handleSubmit = () => {
        if (!currentUser) {
            setShowSignInPopup(true);
            return;
        }

        if (question.trim() === '') return;

        if (userLoading) {
            alert('Loading user data, please wait...');
            return;
        }

        if (userError || !userData) {
            alert('Failed to load user data. Please try again.');
            return;
        }

        if (userData.credits <= 0) {
            setShowNoCreditsModal(true);
            return;
        }

        const userMessage = { role: 'user', content: question };

        // Append the user's message immediately
        setLocalMessages((prevMessages) => [...prevMessages, userMessage]);

        setIsAssistantTyping(true);

        saveMessage(userMessage, {
            onSuccess: (assistantResponse) => {
                setIsAssistantTyping(false);
                // Append the assistant's response
                setLocalMessages((prevMessages) => [...prevMessages, assistantResponse]);
                queryClient.refetchQueries('user');
            },
            onError: (error) => {
                setIsAssistantTyping(false);
                console.error('Failed to save message:', error);
                // Optionally remove the user's message if saving failed
            },
        });

        setQuestion('');
    };

    const handlePreparedQuestionClick = (preparedQuestion) => {
        setQuestion(preparedQuestion);
        setIsPreparedQuestion(true);
    };

    useEffect(() => {
        if (isPreparedQuestion) {
            handleSubmit();
            setIsPreparedQuestion(false); // Reset to avoid future triggers
        }
    }, [isPreparedQuestion]);

    const handleReset = () => {
        if (!currentUser) {
            alert('You must be signed in to reset the chat.');
            return;
        }

        if (window.confirm('Are you sure you want to reset the chat?')) {
            clearMessages(null, {
                onSuccess: () => {
                    setLocalMessages([]);
                },
                onError: (error) => {
                    console.error('Failed to clear messages:', error);
                },
            });
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit();
        }
    };

    return (
        <div className="flex-1 h-screen flex flex-col p-4 pt-16 md:pt-4">
            {showSignInPopup && <SignInPopup onClose={() => setShowSignInPopup(false)} />}
            {showNoCreditsModal && (
                <NoCreditsModal
                    onClose={() => setShowNoCreditsModal(false)}
                    onGetMoreCredits={() => setShowProfileModal(true)}
                />
            )}
            {showProfileModal && (
                <ProfileModal
                    user={userData}
                    googleUser={currentUser}
                    onClose={() => setShowProfileModal(false)}
                    expandCredits={true}
                />
            )}
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">{selectedTopic?.name || ''}</h2>
                <div className="flex items-center">
                    {currentUser ? (
                        userLoading ? (
                            <p>Loading credits...</p>
                        ) : userError ? (
                            <p>Error loading credits</p>
                        ) : (
                            <p
                                className={`${userData?.credits <= 3 ? 'text-red-500' : 'text-black'
                                    }`}
                            >
                                Credits: {userData?.credits}
                            </p>
                        )
                    ) : null}
                    <button onClick={handleReset} className="ml-4 text-gray-500 hover:text-red-500">
                        <FaRedo size={20} />
                    </button>
                </div>
            </div>
            <div ref={chatContainerRef} className="flex-1 bg-gray-100 p-4 overflow-y-auto mb-4">
                {localMessages.map((msg, index) => (
                    <ChatMessage key={index} text={msg.content || msg.text} isUser={msg.role === 'user'} />
                ))}
                {isAssistantTyping && (
                    <div className="mt-2">
                        <TypingIndicator />
                    </div>
                )}
            </div>
            {localMessages.length === 0 && (
                <div className="flex flex-wrap gap-2 mb-4">
                    {(selectedTopic?.preparedQuestions || []).map((q, index) => (
                        <button
                            key={index}
                            onClick={() => handlePreparedQuestionClick(q)}
                            className="bg-gray-200 text-gray-800 px-3 py-2 rounded-md hover:bg-gray-300"
                        >
                            {q}
                        </button>
                    ))}
                </div>
            )}
            <div className="flex items-center mb-2">
                <TextareaAutosize
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="Ask a question..."
                    className="border rounded w-full p-2 resize-none overflow-y-auto"
                    minRows={1}
                    maxRows={5}
                />
                <button
                    onClick={handleSubmit}
                    className="ml-2 bg-blue-500 text-white rounded px-4 py-2 hover:bg-blue-600"
                >
                    Send
                </button>
            </div>
            <div className="text-sm text-gray-600 mb-2 text-center">
                <p className="mt-2">
                    Please avoid sharing sensitive information in your queries, as chats may be reviewed to enhance service quality.
                </p>
            </div>
        </div>
    );
};

export default ChatWindow;
