import React from 'react';

const TermsOfUse = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen p-4">
            <h1 className="text-3xl font-bold mb-4">Terms of Use</h1>
            <p className="text-gray-600">This is the Terms of Use page. Content coming soon!</p>
        </div>
    );
};

export default TermsOfUse;