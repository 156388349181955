import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { signOut } from '../services/authService';
import { useTopics } from '../hooks/useTopics';
import UserMenu from './UserMenu';
import { FaTelegramPlane, FaEnvelope } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Sidebar = ({ isOpen, onClose, onSelectTopic, onSignIn, onProfileClick }) => {
    const { data: topics = [], isLoading: topicsLoading, isError: topicsError } = useTopics();
    const [selectedTopic, setSelectedTopic] = useState(null);
    const { currentUser } = useContext(AuthContext);

    const preparedQuestions = {
        1: [
            "What are the legal requirements for overtime pay in Korea?",
            "How many vacation days am I entitled to under Korean labor law?"
        ],
        2: [
            "What expenses can I deduct from my income tax in Korea?",
            "How does the progressive tax rate work for income tax in Korea?"
        ],
        3: [
            "What are the corporate tax rates for small businesses in Korea?",
            "How can a company reduce its corporate tax liability legally?"
        ],
    };

    useEffect(() => {
        if (topics.length > 0 && !selectedTopic) {
            setSelectedTopic(topics[0]);
            onSelectTopic({ ...topics[0], preparedQuestions: preparedQuestions[topics[0].id] || [] });
        }
    }, [topics]);

    const handleTopicClick = (topic) => {
        setSelectedTopic(topic);
        onSelectTopic({ ...topic, preparedQuestions: preparedQuestions[topic.id] || [] });
        onClose(); // Close sidebar when a topic is selected
    };

    const handleAuthAction = async () => {
        if (currentUser) {
            await signOut();
            onClose(); // Close sidebar when user signs out
        } else {
            onSignIn();
            onClose(); // Close sidebar when sign-in popup is shown
        }
    };

    const handleProfileClick = () => {
        onProfileClick();
        onClose(); // Close sidebar when profile modal is shown
    };

    const handleOutsideClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const SidebarContent = () => {
        return (
            <div className="flex flex-col justify-between h-full">
                <div>
                    <h1 className="text-3xl font-extrabold text-blue-600 mb-2 tracking-wide">
                        <span className="text-gray-700">Lexcha</span>
                    </h1>
                    <p className="text-gray-600 mb-8">AI-Powered Korean Legal Assistant</p>
                    {topicsLoading ? (
                        <p>Loading topics...</p>
                    ) : topicsError ? (
                        <p className="text-red-500">Failed to load topics.</p>
                    ) : (
                        <ul>
                            {topics.map((topic) => (
                                <li
                                    key={topic.id}
                                    onClick={() => handleTopicClick(topic)}
                                    className={`cursor-pointer p-3 mb-2 rounded-md ${selectedTopic && selectedTopic.id === topic.id
                                        ? 'bg-blue-500 text-white font-semibold'
                                        : 'bg-gray-100 hover:bg-gray-300 text-gray-700'
                                        }`}
                                >
                                    {topic.name}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <div className="mt-8">
                    {/* Footer Section */}
                    <div className="border-t border-gray-300 pt-4">
                        <ul className="space-y-2">
                            <li>
                                <Link
                                    to="/terms-of-use"
                                    className="text-gray-700 hover:text-blue-500"
                                    onClick={onClose} // Close sidebar on link click
                                >
                                    Terms of Use
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/privacy-policy"
                                    className="text-gray-700 hover:text-blue-500"
                                    onClick={onClose} // Close sidebar on link click
                                >
                                    Privacy Policy
                                </Link>
                            </li>
                        </ul>
                        <div className="mt-4 flex space-x-4">
                            <a
                                href="https://t.me/your_telegram_handle"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-gray-700 hover:text-blue-500"
                                aria-label="Telegram"
                            >
                                <FaTelegramPlane size={20} />
                            </a>
                            <a
                                href="mailto:support@lexcha.xyz"
                                className="text-gray-700 hover:text-blue-500"
                                aria-label="Email"
                            >
                                <FaEnvelope size={20} />
                            </a>
                        </div>
                    </div>
                    {/* Authentication Section */}
                    <div className="flex mt-12 mb-2">
                        {currentUser ? (
                            <UserMenu
                                currentUser={currentUser}
                                onProfileClick={handleProfileClick}
                                onSignOut={handleAuthAction}
                                onItemClick={onClose} // Close the sidebar when an item is clicked
                            />
                        ) : (
                            <button
                                onClick={handleAuthAction}
                                className="flex items-center bg-white border border-gray-300 rounded-md p-1 hover:bg-gray-100"
                            >
                                <img
                                    src="https://developers.google.com/identity/images/btn_google_signin_dark_normal_web.png"
                                    alt="Google Sign-In"
                                    className="w-full"
                                />
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            {/* Mobile Sidebar */}
            {isOpen && (
                <div
                    className="fixed inset-0 z-40 flex"
                    onClick={handleOutsideClick}
                >
                    <div
                        className="w-1/8 bg-gray-200 p-4"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <SidebarContent />
                    </div>
                </div>
            )}

            {/* Desktop Sidebar */}
            <div className="hidden md:block w-1/8 bg-gray-200 p-4">
                <SidebarContent />
            </div>
        </>
    );
};

export default Sidebar;
