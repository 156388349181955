import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

const ChatMessage = ({ text, isUser }) => {
    // Replace new lines with Markdown-compatible line breaks
    const formattedText = text.replace(/\n/g, '  \n');

    return (
        <div className={`p-2 ${isUser ? 'text-right' : 'text-left'}`}>
            <div className={`inline-block p-3 rounded-lg ${isUser ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}>
                <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                    components={{
                        h1: ({ children }) => <h1 className="text-2xl font-bold mt-4 mb-2">{children}</h1>,
                        h2: ({ children }) => <h2 className="text-xl font-semibold mt-3 mb-2">{children}</h2>,
                        h3: ({ children }) => <h3 className="text-lg font-semibold mt-3 mb-2">{children}</h3>,
                        h4: ({ children }) => <h4 className="text-md font-medium mt-2 mb-1">{children}</h4>,
                        h5: ({ children }) => <h5 className="text-sm font-medium mt-2 mb-1">{children}</h5>,
                        h6: ({ children }) => <h6 className="text-xs font-medium mt-1 mb-1">{children}</h6>,
                        p: ({ children }) => <p className="mb-2">{children}</p>,
                        li: ({ children }) => <li className="ml-4 list-decimal">{children}</li>,
                    }}
                >
                    {formattedText}
                </ReactMarkdown>
            </div>
        </div>
    );
};

export default ChatMessage;
