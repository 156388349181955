// src/components/DisclaimerModal.js
import React from 'react';

const DisclaimerModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full mx-4">
                <h2 className="text-xl font-bold mb-4">Welcome to Our Service</h2>
                <p className="mb-4">
                    Our service is intended to assist you in exploring Korean laws based on officially published resources. It does not provide legal advice. For personalized legal advice, please consult a qualified legal professional or law firm.
                </p>
                <button
                    onClick={onClose}
                    className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                >
                    I Understand
                </button>
            </div>
        </div>
    );
};

export default DisclaimerModal;
