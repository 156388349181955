import * as Sentry from "@sentry/react";

/**
 * Initializes Sentry only if in production and DSN is provided.
 */
const initializeSentry = () => {
    if (process.env.NODE_ENV !== 'production') {
        console.log("Sentry not initialized: Not in production environment.");
        return;
    }

    if (!process.env.REACT_APP_SENTRY_DSN) {
        console.warn("Sentry DSN is not provided. Sentry will not be initialized.");
        return;
    }

    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    console.log("Sentry initialized successfully.");
};

export default initializeSentry;
