import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getMessages, postMessage, deleteMessages } from '../api/lawChatApi';

export const useMessages = (topicId, currentUser, modeId = 1) => {
    const queryClient = useQueryClient();

    const { data: messages, refetch: refetchMessages } = useQuery(
        ['messages', topicId],
        () => getMessages(topicId),
        {
            enabled: !!topicId && !!currentUser, // Only fetch if authenticated
        }
    );

    const saveMessage = useMutation((message) => postMessage(topicId, message, modeId), {
        onSuccess: (newMessage) => {
            // Directly update the cache to avoid refetch
            queryClient.setQueryData(['messages', topicId], (oldMessages) => {
                return oldMessages ? [...oldMessages, newMessage] : [newMessage];
            });
        },
    });

    const clearMessages = useMutation(() => deleteMessages(topicId), {
        onSuccess: () => {
            queryClient.invalidateQueries(['messages', topicId]);
        },
    });

    return {
        messages,
        refetchMessages,
        saveMessage: saveMessage.mutate,
        clearMessages: clearMessages.mutate,
    };
};
